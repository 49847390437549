import { init } from "./commons"
import $ from "./lib/jquery"

import "./lib/slick.min"

function activateSlider () {
   const myRtl = $("html").prop("dir") === "rtl";

   var $mySlides = $(".slide");
   $mySlides.css({ height : $(window).innerHeight() - $mySlides.offset().top });

   $(".slider").slick({
      autoplay : true,
      arrows: false,
      mobileFirst : true,
      fade : true,
      autoplaySpeed : 10000,
      speed : 10000,
      pauseOnFocus : false,
      rtl : myRtl
   });
}

console.log("Activating")

init()
activateSlider()
