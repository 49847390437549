import $ from "./lib/jquery"
import moment from "./lib/moment.js"

export function logToServer (aLevel, aMessage, aUrl, aLineNumber, aColumnNumber, aData) {
   try {
      if (aData) {
         if (aData instanceof Error) {
            var myDataObject = JSON.stringify(aData, Object.getOwnPropertyNames(aData));
         }
         else {
            var myDataObject = JSON.stringify(aData);
         }
      }

      $.post(
         "/log/" + aLevel,
         {
            message : aMessage,
            url : aUrl,
            lineNumber : aLineNumber,
            columnNumber : aColumnNumber,
            dataObject : myDataObject
         }
      )
   }
   catch(aError) {}
};

export function getLocaleSeparator (aType) {

   // This is the ideal way to get the users locale separator
   // but not always supported
   try {
      if (window.Intl
            && typeof window.Intl === "object"
            && window.Intl.NumberFormat
            && window.Intl.NumberFormat().formatToParts) {
         var testNumber = 1000.1

         var myPart = Intl.NumberFormat()
            .formatToParts(testNumber)
            .find (function (aPart) { return aPart.type === aType});

         if (myPart) {
            return myPart.value;
         }
      }
   }
   catch (aError) {
      logToServer("warn", "Failed to determine locale separator", null, null, null, aError);
   }

   // This is the alternative way, more browser support.
   var testNumber = 1.1;
   var decimalSeparator = testNumber.toLocaleString().substring(1,2)
   switch (aType) {
      case "decimal": return decimalSeparator;
      case "group": return (decimalSeparator == "." ? "," : ".");
      default : return "";
   }
}

export function formatNumber (aNumber) {
   try {
      if (
         window.Intl
         && typeof window.Intl === "object"
         && window.Intl.NumberFormat
         && window.Intl.NumberFormat().format
      ) {
         return Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            .format(aNumber);
      }
      else {
         return aNumber.toLocaleString()
      }
   }
   catch (aError) {
      logToServer("warn", "Failed to format number", null, null, null, aError);
   }

   return String(aNumber)
}

export function supportsDatePicker () {
   var myTestInput = document.createElement("input");
   var myReferenceValue = "abc"
   myTestInput.setAttribute("type", "date")
   myTestInput.setAttribute("value", myReferenceValue)
   return myTestInput.value !== myReferenceValue;
}

function updateQueryStringLegacy (uri, key, value) {
   var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
   if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
   }
   else {
      var hash =  '';
      if( uri.indexOf('#') !== -1 ){
         hash = uri.replace(/.*#/, '#');
         uri = uri.replace(/#.*/, '');
      }
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      return uri + separator + key + "=" + value + hash;
   }
}

export function changeLanguage (aLanguage) {
   let mySearch = window.location.search
   if (window.URLSearchParams) {
      let myParams = new URLSearchParams(mySearch)
      myParams.set("locale", aLanguage)
      mySearch = myParams.toString()
   } else {
      mySearch = updateQueryStringLegacy(mySearch, "locale", aLanguage)
   }

   window.location.search = mySearch
}

export function isValidDate(target) {
   // Validates that the input string is a valid date formatted as "yyyy-mm-dd"
   /* mini-tutorial on input type='date'
   In modern browsers (after firefox version 57, 2017) if the input element has a type of 'date', it will use the OS
   language and locale settings to determine the format of the date display. Note that it will NOT use the browser
   formatting options. It will force the user to enter data in accordance with the OS formatting.
   However, the element.value will ALWAYS be yyyy-mm-dd, no matter what the OS formatting is.
   By contrast:
   In older browsers (pre firefox version 57), the input element will end up as a text field, and will accept any text.
   As of June 2022, only 3.5 % of all browsers in use fail to handle type=date. We do all the work below to handle that
   3.5%, which basically consists of checking the element.value to see if it is formatted per yyyy-mm-dd, regardless
   of the OS settings (which we neglect).
   */
   var dateString = target.value;

   //3rd param means "strict", match perfectly
   var dateValid= moment(dateString, 'YYYY-MM-DD', true);
   console.log("using moment.js, date value is: " + dateValid.isValid());

   var currentDate = new Date();
   var formattedDate = new Date(dateString);
   //we require all dates to be today or earlier, not future
   //as they are reporting dates which already happened (not arrival dates etc)
   if (formattedDate > currentDate){
      console.log("Date entered is after current date, reject.");
      return false;
   }


   return dateValid.isValid();
 }

export function init () {

   window.logToServer = logToServer
   window.changeLanguage = changeLanguage

   window.onerror = function(aMessage, aUrl, aLineNumber, aColumnNumber, aError) {
      logToServer ("error", aMessage, aUrl, aLineNumber, aColumnNumber, aError);
   }

   $(window).ajaxError(function(aEvent, aRequest, aSettings, aError) {
      // Don't log an error if the error call is what failed
      if (aSettings.url == "/log/error") {
         return;
      }

      logToServer(
         "error",
         "Failure connecting to server: " + aError + " | Status: " + aRequest.status,
         aSettings.url,
         null,
         null,
         aRequest.responseText
      );
   });

   $.postJSON = function(aUrl, aData, aSuccessCallback) {

      var myData = aData;

      if (typeof myData !== "string") {
         myData = JSON.stringify(myData);
      }

      return $.ajax({
         url : aUrl,
         type : "POST",
         dataType : "json",
         data : myData,
         contentType : "application/json",
         mimeType : "application/json",
         success : aSuccessCallback
      });
   }

   $(".toggle-radio").click(function(aEvent) {
      $(this).tab("show");
   })

}

export const renderTemplateTo = (aContainerSelector, aTemplateId) => {
   const myContainer = document.querySelector(aContainerSelector);

   if (aTemplateId) {
      myContainer.innerHTML = document.getElementById(aTemplateId).innerHTML;
   } else {
      myContainer.innerHTML = ""
   }
}

export const submitWithCaptcha = (aForm) => {
   return new Promise((result, error) => {

      const submitInternal = (aToken) => {
         fetch(aForm.action, {
            method: aForm.method,
            body: myFormData,
            headers: {
               "X-CSI-Recaptcha": aToken
            }
         })
         .then(aResponse => {
            if (aResponse.redirected) {
               const myLocation = aResponse.url
               console.log("Redirecting to " + myLocation)
               window.location.href = myLocation
            } else {
               if (aResponse.ok) {
                  result(aResponse)
               } else {
                  error(aError)
               }
            }
         })
         .catch(aError => {
            error(aError)
         })
      }

      console.log("Starting submit")

      const myFormData = new FormData(aForm)
      const myKey = aForm.dataset.key

      if (window.grecaptcha && myKey) {
         logToServer("info", "LIFECYCLE: starting reCAPTCHA check")
         window.grecaptcha.enterprise.ready(() => {
            logToServer("info", "LIFECYCLE: reCAPTCHA ready")
            window.grecaptcha.enterprise.execute(myKey, { action: 'login' })
               .then(submitInternal)
               .catch (aError => {
                  logToServer("error", "Failed to submit form with captcha.", null, null, null, aError)
                  error(aError)
               })
         })
      }
      else {
         submitInternal()
      }
   });
}


export function luhnCheck(aCardNumber) {
   let mySum = 0;
   let myIsEven = false;
   
   for (let i = aCardNumber.length - 1; i >= 0; i--) {
     let myDigit = parseInt(aCardNumber.charAt(i), 10);
     
     if (myIsEven) {
       myDigit *= 2;
       if (myDigit > 9) {
         myDigit -= 9;
       }
     }
     
     mySum += myDigit;
     myIsEven = !myIsEven;
   }
   
   return (mySum % 10) === 0;
}
 
export function isCreditCard(aInput) {
   const myCleaned = aInput.replace(/\D/g, '')

   if (myCleaned.length < 13 || myCleaned.length > 19) {
      return false
   }

   return luhnCheck(myCleaned)
}
